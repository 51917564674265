import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import Pricing from "../components/Index/Pricing"
 
const Details = () => {
    return (
        <Layout>
            <Navbar />

            <PageBanner
                pageTitle="Pricing" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Pricing" 
            />

            <Pricing />
            <Footer />
        </Layout>
    );
}

export default Details